import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/common/modules/material.module';

import { CustomerAttractivenessStrategyComponent } from '../components/customer-attractiveness-strategy/customer-attractiveness-strategy.component';
import { PhoneModelsLayoutComponent } from '../components/phone-models-layout/phone-models-layout.component';
import { RepairingByCategoryComponent } from '../components/repairing-by-category/repairing-by-category.component';
import { SearchMobileModelComponent } from '../components/search-mobile-model/search-mobile-model.component';

@NgModule({
  declarations: [
    CustomerAttractivenessStrategyComponent,
    PhoneModelsLayoutComponent,
    SearchMobileModelComponent,
    RepairingByCategoryComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [
    CustomerAttractivenessStrategyComponent,
    PhoneModelsLayoutComponent,
    SearchMobileModelComponent,
    RepairingByCategoryComponent
  ],
})

export class HomePageModule { }