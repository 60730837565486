<form [formGroup]="registrationForm">
    <div fxLayoutAlign="center center" fxLayout="column">
        <mat-horizontal-stepper class="nextline-stepper" [linear]="isLinear" labelPosition="bottom" #stepper>
            <mat-step formGroupName="MobileNoGroup" [stepControl]="registrationForm.get('MobileNoGroup')">
                <ng-template matStepLabel>Mobile No.</ng-template>
                <mat-form-field appearance="outline" fxLayoutAlign="center center">
                    <mat-label>Enter Your Mobile No.</mat-label>
                    <input matInput formControlName="MobileNoCtrl" required>
                </mat-form-field>
                <div>
                    <button mat-raised-button matStepperNext color="primary">Submit</button>
                </div>
            </mat-step>
            <mat-step formGroupName="MobileBrandGroup" [stepControl]="registrationForm.get('MobileBrandGroup')">
                <ng-template matStepLabel>Brand</ng-template>
                <app-mobile-brand [stepper]="stepper" [brandForm]="registrationForm"></app-mobile-brand>
            </mat-step>
            <mat-step formGroupName="MobileModelGroup" [stepControl]="registrationForm.get('MobileModelGroup')">
                <ng-template matStepLabel>Model</ng-template>
                <app-mobile-models [stepper]="stepper" [modelsForm]="registrationForm"></app-mobile-models>
            </mat-step>
            <mat-step formArrayName="MobileIssue" [stepControl]="registrationForm.get('MobileIssue')">
                <ng-template matStepLabel>Issues</ng-template>
                <app-mobile-issues [issuesForm]="registrationForm"></app-mobile-issues>
            </mat-step>
            <mat-step formGroupName="MobileDetailsGroup" [stepControl]="registrationForm.get('MobileDetailsGroup')">
                <ng-template matStepLabel>Details</ng-template>
                <app-mobile-details [detailsForm]="registrationForm"></app-mobile-details>
            </mat-step>
            <mat-step formArrayName="Accessory" [stepControl]="registrationForm.get('Accessory')">
                <ng-template matStepLabel>Accessories Include</ng-template>
                <app-mobile-accessories [accessoryForm]="registrationForm"></app-mobile-accessories>
            </mat-step>
            <mat-step formGroupName="PlaceOrderGroup" [stepControl]="registrationForm.get('PlaceOrderGroup')">
                <ng-template matStepLabel>Place Repair Order</ng-template>
                <app-place-order [placeOrderForm]="registrationForm"></app-place-order>
                <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center">
                    <button mat-raised-button matStepperPrevious color="warn">Back</button>
                    <button mat-raised-button (click)="stepper.reset()">Booked</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</form>