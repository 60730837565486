<div [formGroup]="modelsForm" fxLayoutAlign="center center">
    <form formGroupName="MobileModelGroup">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h2>Select The Model You Want To Repair</h2>
            <mat-form-field appearance="outline">
                <input matInput formControlName="MobileModelCtrl" placeholder="SearchModel">
            </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
            <mat-chip-list [selectable]="true">
                <mat-chip #chip="matChip" *ngFor="let issue of issues" [disableRipple]="true" [value]="issue"
                    (click)="toggleSelection(chip);goToNext()">{{issue.type}}
                </mat-chip>
            </mat-chip-list>
        </div>
        <div>
            <button mat-raised-button matStepperPrevious color="warn" style="margin-top: 10px;">Back</button>
        </div>
    </form>
</div>