<div class="search-container" [ngStyle.xs]="{'padding':'10px'}">
    <h2>Repair Your Phone at Doorstep</h2>
    <div fxHide.xs class="list-container">
        <ul>
            <li>
                <mat-icon>done</mat-icon>
                <span>DoorStep Service</span>
            </li>
            <li>
                <mat-icon>done</mat-icon>
                <span>Repairing Bazaar Guarntee</span>
            </li>
            <li>
                <mat-icon>done</mat-icon>
                <span>Best Prices</span>
            </li>
        </ul>
    </div>
    <mat-form-field appearance="outline" [ngStyle.xs]="{'width':'100%'}">
        <mat-label>Search Your Mobile for Repairing</mat-label>
        <input matInput type="text" [(ngModel)]="value">
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</div>