<div [formGroup]="detailsForm">
    <form formGroupName="MobileDetailsGroup" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
            <mat-label>Full Name</mat-label>
            <input matInput formControlName="fullName">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email Id</mat-label>
            <input matInput formControlName="emailId">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Aadhar Card</mat-label>
            <input matInput formControlName="aadharCard" mask="0000 0000 0000">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>IMEI</mat-label>
            <input matInput formControlName="IMEINumber">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>PinCode</mat-label>
            <input matInput formControlName="pinCode">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>PhoneNo</mat-label>
            <input matInput formControlName="phoneNo">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <input matInput formControlName="state">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput formControlName="city">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>AddressLine1</mat-label>
            <input matInput formControlName="addressLine1">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>AddressLine2</mat-label>
            <input matInput formControlName="addressLine2">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Landmark</mat-label>
            <input matInput formControlName="landmark">
        </mat-form-field>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center">
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
    </form>
</div>