import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})

export class PlaceOrderComponent {

  @Input() placeOrderForm: UntypedFormGroup;
  date = 0;

  dateTime = [
    { id: 'myCheck1', for: 'myCheck1', date: 'Saturday, 10 April 2021' },
    { id: 'myCheck2', for: 'myCheck2', date: 'Monday, 11 April 2021' },
    { id: 'myCheck3', for: 'myCheck3', date: 'Tuesday, 12April 2021' },
  ];
  scheduleDate() {
    // console.log({ data, i });
    console.log(this.placeOrderForm);
    console.log(this.placeOrderForm.get('PlaceOrderGroup').get('PlaceOrderCtrl').value);
    // console.log(this.repairForm.get('MobileIssuesGroup').get('MobileIssuesCtrl').value);

    // this.date = i;
  };


}