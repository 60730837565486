import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChip } from '@angular/material/chips';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-mobile-models',
  templateUrl: './mobile-models.component.html',
  styleUrls: ['./mobile-models.component.scss']
})

export class MobileModelsComponent {
  @Input() modelsForm: UntypedFormGroup;
  @Input() stepper: MatStepper;

  issues = [
    { type: 'IC' },
    { type: 'Mobile Jack' },
    { type: 'Aux' },
    { type: 'Hosing' },
    { type: 'Display Glass' },
    { type: 'Chrome' },
    { type: 'Mic' },
    { type: 'Keypad' },
    { type: 'Display Touch' },
    { type: 'Memory/Simslot' }
  ];

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
  }

  goToNext() {
    this.stepper.next();
  }
}