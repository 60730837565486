<div fxLayout fxLayoutAlign="space-around" fxLayout.lt-md="column" fxLayoutGap="10px">
    <div class="inside-container" fxFlex="60%" fxLayoutGap="20px" fxLayout="column">
        <div fxLayout fxLayoutAlign="space-between center">
            <div class="delivery-address">Select Delivery Address</div>
            <button fxHide.xs mat-stroked-button class="address-button">
                ADD NEW ADDRESS</button>
        </div>
        <strong [ngStyle]="{'font-size':'14px'}">DEFAULT ADDRESS</strong>
        <div *ngFor="let data of addressList;let i=index">
            <mat-card class="address-card" fxLayout="column">
                <div fxLayout>
                    <mat-radio-button value="i" color="warn" [checked]="i===selectedAddress"
                        (change)="onSelctedAddress(data,i)">{{data.name}} </mat-radio-button>
                    <mat-chip disabled>{{data.place}}</mat-chip>
                </div>
                <div [ngStyle]="{'margin-left':'17px'}">
                    <p>{{data.address}},{{data.locality}},{{data.city}} <br>{{data.state}},{{data.pincode}}</p>
                    <p>Mobile: {{data.mobile_no}}</p>
                </div>
                <li>Pay on Delivery available</li>
                <div fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="i === selectedAddress">
                    <button mat-stroked-button class="remove-button" color="warn">REMOVE</button>
                    <button mat-stroked-button class="edit-button" color="warn">EDIT</button>
                </div>
            </mat-card>
        </div>
        <button class="checkout-address" fxHide.gt-xs> + Add New Address </button>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="delivery-card" fxLayout="column" fxFlex="30%">
        <h5>DELIVERY ESTIMATES</h5>
        <span [ngStyle]="{'color':'red'}"><strong>Please Note: </strong></span>
        Dear customer, our delivery timelines are currently longer than
        usual. Stay home, stay safe and stay stylish while we are trying to deliver your order at earliest.
        <div fxLayout="column" [ngStyle]="{'margin-top':'20px'}" fxLayoutGap="20px">
            <div *ngFor="let data of checkoutAddress" fxLayout fxLayoutAlign="start center">
                <img src="{{data.img}}" alt="photo">
                <span [ngStyle]="{'margin-left':'20px'}">Estimated Delivery by {{data.date}}</span>
            </div>
            <span>PRICE DETAILS (2 Items)</span>
            <div *ngIf="hidden" fxLayoutGap="15px" fxLayout="column">
                <div fxLayoutAlign="space-between center">
                    Bag Total<span>&#8377; {{bagtotal}}</span>
                </div>
                <div fxLayoutAlign="space-between center">
                    Bag Discount<span>-&#8377; {{bagdiscount}}</span>
                </div>
                <div fxLayoutAlign="space-between center">
                    Coupon Discount<span>-&#8377; {{coupondiscount}}</span>
                </div>
            </div>
            <div fxLayoutAlign="space-between center">
                <span>Order Total <a (click)="toggle()" class="viewdetails-conatiner">{{ViewDetails}}</a></span>
                <span>&#8377; {{order}}</span>
            </div>
            <div fxLayoutAlign="space-between center">
                Delivery Charges <span><del> &#8377; 99 </del>FREE</span>
            </div>
            <div fxLayoutAlign="space-between center">
                <b>Total</b><span><b>&#8377; {{order}} </b></span>
            </div>
            <button mat-stroked-button class="continue-button">CONTINUE</button>
        </div>
    </div>
</div>