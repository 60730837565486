import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IphoneModel } from '../../models/mobile-repair.model';
import { MatStepper } from '@angular/material/stepper';
import { UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-mobile-brand',
  templateUrl: './mobile-brand.component.html',
  styleUrls: ['./mobile-brand.component.scss']
})

export class MobileBrandComponent {
  @Input() brandForm: UntypedFormGroup;
  @Input() stepper: MatStepper;
  searchText;
  public cols: Observable<number>;
  public colspan: number;
  public rowspan: number;

  constructor(private breakpointObserver: BreakpointObserver) {

    this.cols = this.getColsByBreakpoint();
    this.colspan = 1;
    this.rowspan = 1;
  };

  public services = [
    { image: 'https://images.ctfassets.net/hrltx12pl8hq/3MbF54EhWUhsXunc5Keueb/60774fbbff86e6bf6776f1e17a8016b4/04-nature_721703848.jpg?fit=fill&w=480&h=270', name: 'Samsung' },
    { image: 'https://static.toiimg.com/photo/72975551.cms', name: 'Lannovo' },
    { image: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg', name: 'Radmi' },
    { image: 'https://image.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg', name: 'Realme' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' },
    { image: '', name: '' }
  ];

  private getColsByBreakpoint() {
    return this.breakpointObserver.observe([
      '(max-width: 359px)',
      '(max-width: 517px)',
      Breakpoints.XSmall,
      '(max-width: 730px)',
      Breakpoints.Small,
      '(max-width: 1100px)',
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).pipe(
      map((result) => {
        if (result.breakpoints['(max-width: 359px)']) { return 2; }
        if (result.breakpoints['(max-width: 517px)']) { return 3; }
        if (result.breakpoints[Breakpoints.XSmall]) { return 2; }
        if (result.breakpoints['(max-width: 730px)']) { return 2; }
        if (result.breakpoints[Breakpoints.Small]) { return 3; }
        if (result.breakpoints['(max-width: 1100px)']) { return 4; }
        if (result.breakpoints[Breakpoints.Medium]) { return 4; }
        if (result.breakpoints[Breakpoints.Large]) { return 4; }
        if (result.breakpoints[Breakpoints.XLarge]) { return 4; }
      })
    );

  }
  goToNext() {
    this.stepper.next();
  }
}