import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-mobile-accessories',
  templateUrl: './mobile-accessories.component.html',
  styleUrls: ['./mobile-accessories.component.scss']
})

export class MobileAccessoriesComponent {

  @Input() accessoryForm: UntypedFormGroup;

  accessoryIncluded = [
    { name: 'Battery', value: 'Battery' },
    { name: 'Back Panel', value: 'Back Panel' },
    { name: 'Sim Tray', value: 'Sim Tray' },
    { name: 'Memory Card Tray', value: 'Memory Card Tray' },
  ];

  onCheckboxChange(e) {
    const Accessory: UntypedFormArray = this.accessoryForm.get('Accessory') as UntypedFormArray;

    if (e.target.checked) {
      Accessory.push(new UntypedFormControl(e.target.value));
    } else {
      let i: number = 0;
      Accessory.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          Accessory.removeAt(i);
          return;
        }
        i++;
      });
    }
    console.log(this.accessoryForm.value)
  }
}