import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgModule } from '@angular/core';

import { ImageSliderComponent } from '../components/image-slider/image-slider.component';


@NgModule({
    declarations: [
        ImageSliderComponent
    ],
    imports: [
      CommonModule,
      CarouselModule
    ],
    exports: [
        ImageSliderComponent
    ]
  })
  
export class ImageSliderModule { }