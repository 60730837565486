<div fxLayoutAlign="center center">
    <mat-horizontal-stepper [linear]="isLinear" class="nextline-stepper" labelPosition="bottom" #stepper>
        <mat-step>
            <ng-template matStepLabel>Bag</ng-template>
            <app-checkout-bag></app-checkout-bag>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Address</ng-template>
            <app-checkout-address></app-checkout-address>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Payment</ng-template>
        </mat-step>
    </mat-horizontal-stepper>
</div>