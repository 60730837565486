<div class="mobile-repair">
    <h1>GET YOUR MOBILE REPAIR STARTED</h1>
</div>
<div class="service-content" [ngStyle.xs]="{'text-align':'justify'}" fxLayout="column" fxLayoutAlign="center center">
    <h2>Services</h2>
    <p>We provide quick service process at our mobile Store. Repair Gyani is one of
        the best reasonable mobile phone service store, we are fixing all models of smartphones, laptops and
        other electronic gadgets. Our service included touch screen repair/replacement, liquid and water damage repair,
        broken and physical damage repair, mainboard chip-level repairing,charging port, power button, side button,
        camera repair, headphone jack, wifi, bluetooth, battery replacement, software troubleshoot and up-gradation.
    </p>
</div>
<div fxLayout fxLayout.xs="column" fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="20px" fxLayoutAlign="center"
    fxLayoutGap.gt-sm="40px" class="service">
    <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" *ngFor="let data of service1"
            [ngStyle.gt-sm]="{'justify-content': 'center','text-align': 'end'}">
            <strong>{{data.heading}}</strong>
            <span>{{data.content}}</span>
        </div>
    </div>
    <div fxHide.lt-md class="image-container">
        <img src="assets/services/service.png" width="180px" height="180px">
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" *ngFor="let data of service2" [ngStyle.gt-sm]="{'justify-content': 'center'}">
            <strong>{{data.heading}}</strong>
            <span>{{data.content}}</span>
        </div>
    </div>
</div>