import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUsModule } from './client/about-us/modules/about-us.module';
import { AuthenticationModule } from './client/authentication/modules/authentication/authentication.module';
import { CheckoutModule } from './client/checkout/modules/checkout.module';
import { HeaderModule } from './client/header/modules/header.module';
import { HomePageModule } from './client/home-page/modules/home-page.module';
import { ImageSliderModule } from './common/modules/image-slider.module';
import { FooterModule } from './common/modules/footer.module';
import { MaterialModule } from './common/modules/material.module';
import { PrivacyAndPolicyComponent } from './common/components/privacy-and-policy/privacy-and-policy.component';
import { ShippingPolicyComponent } from './common/components/shipping-policy/shipping-policy.component';
import { SmartphoneComponent } from './client/smartphone/smartphone.component';
import { TermsAndConditionsComponent } from './common/components/terms-and-conditions/terms-and-conditions.component';
import { MobileRepairModule } from './client/mobile-repair/modules/mobile-repair.module';
import { WishlistComponent } from './client/wishlist/wishlist.component';

@NgModule({
  declarations: [
    PrivacyAndPolicyComponent,
    ShippingPolicyComponent,
    SmartphoneComponent,
    TermsAndConditionsComponent,
    WishlistComponent
  ],
  imports: [
    AboutUsModule,
    AuthenticationModule,
    CommonModule,
    CheckoutModule,
    FooterModule,
    HeaderModule,
    HomePageModule,
    MaterialModule,
    MobileRepairModule
  ],
  exports: [
    AboutUsModule,
    AuthenticationModule,
    CheckoutModule,
    FooterModule,
    HeaderModule,
    HomePageModule,
    ImageSliderModule,
    FooterModule,
    MobileRepairModule,
    PrivacyAndPolicyComponent,
    ShippingPolicyComponent,
    SmartphoneComponent,
    TermsAndConditionsComponent,
    WishlistComponent
  ],
})

export class ClientModule { }