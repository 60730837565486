<div class="termsandconditions">
    <fieldset fxLayout="column" fxLayoutGap="20px">
        <legend>
            <h2>Terms & Conditions</h2>
        </legend>
        <span>Repair Gyani is owned by Kaka Tel Wale to the needs of individual customers, wholesalers, retailers, and
            corporates by serving them with digital-based value-added products/services through an online and offline
            channel.</span>
        <span>The terms "We"/"Us"/"Our"/"Company" individually and collectively refer to Kaka Tel Wale and the terms
            "Visitor" "User" refer to the users.</span>
        <span>This page introduces the visitors to the Terms and Conditions under which they may visit this website. We
            urge the users to read the page attentively and exit the website if they don’t agree with the mentioned
            clause. The business, any of its business divisions and/or its subsidiaries, associate companies or
            subsidiaries or such other investment companies (in India ) reserve their respective rights to revise these
            Terms and Conditions at any time by updating this posting. Please visit this page periodically and keep
            yourself re-appraised with the Terms and Conditions, because they are meant for each user.</span>

        <div>
            <h2>Use of Content</h2>
            <span>All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations
                appearing
                in this site, except as otherwise noted, are properties either owned or used under license, by the
                business
                and/or it's associated entities featuring on this website. The use of these properties or any other
                content
                on the site, except as provided in these terms and conditions or in the site content, is strictly
                prohibited.</span>
            <span>Selling or modifying the content in any form or reproducing, displaying, publicly performing,
                distributing
                is not allowed to anyone. Using the materials in any way for any public or commercial purpose without
                the
                respective organization’s or entity’s written permission would be considered a violation.</span>
        </div>
        <div>
            <h2>Acceptable Website Use</h2>
            (A) Security Rules
            Visitors are prohibited from violating or attempting to violate the security of the website, including,
            without limitation, (1) accessing data not intended for such user or logging into a server or account which
            the user is not authorized to access, (2) attempting to probe, scan or test the vulnerability of a system or
            network or to breach security or authentication measures without proper authorization, (3) attempting to
            interfere with service to any user, host or network, including, without limitation, via means of submitting
            a virus or "trojan horse" to the website, overloading, "flooding", "mailbombing" or "crashing", or (4)
            sending unsolicited electronic mail, including promotions and/or advertising of products or services.
            Violating either the system or network security may result in civil or criminal liability. The business
            and/or its associate entities under all conditions possess the right to investigate occurrences they suspect
            as involving violations and have the right to involve and cooperate with, law enforcement authorities in
            prosecuting users involved in such violations.<br /><br />
            (B) General Rules
            Visitors are forbidden to use the website to transmit, distribute, store or destroy material (a) that could
            constitute or encourage conduct that would be considered a criminal offense or violates any applicable law
            or regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other
            intellectual property rights of others or violate the privacy or publicity of other personal rights of
            others, or (c) that is libelous, defamatory, pornographic, profane, obscene, threatening, abusive or
            hateful.
        </div>
        <span>
            <h2>Indemnity</h2>
            The user agrees to defend, indemnify, and hold harmless the Company and / or its officers, directors,
            employees and agents, from and against any claims, actions or demands and/or liabilities and/or losses
            and/or damages,alleging or resulting from their use of www.repairgyani.com or their breach of the
            terms.
        </span>
        <span>
            <h2>Liability</h2>
            User agrees that neither Company nor its group companies, directors, officers or employee shall be
            liable
            for any direct or/and indirect or/and incidental or/and special or/and consequential or/and
            exemplary
            damages, resulting from the use or/and the inability to use the service or/and for cost of
            procurement of
            substitute goods or/and services or resulting from any goods or/and data or/and information or/and
            services
            purchased or/and obtained or/and messages received or/and transactions entered into through or/and
            from the
            service or/and resulting from unauthorized access to or/and alteration of user's transmissions
            or/and data
            or/and arising from any other matter relating to the service, including but not limited to, damages
            for loss
            of profits or/and use or/and data or other intangible, even if Company has been advised of the
            possibility
            of such damages.<br /><br />
            User further agrees that Company shall not be liable for any damages arising from interruption,
            suspension
            or termination of service, including but not limited to direct or/and indirect or/and incidental
            or/and
            special consequential or/and exemplary damages, whether such interruption or/and suspension or/and
            termination was justified or not, negligent or intentional, inadvertent or advertent.
            User agrees that Company shall not be responsible or liable to user, or anyone, for the statements
            or
            conduct of any third party of the service. In no event shall Company's total liability to the User
            for all
            damages or/and losses or/and causes of action exceed the amount paid by the User to Company, if any,
            that is
            related to the cause of action.
        </span>
        <span>
            <h2> Disclaimer of Consequential Damages</h2>
            In no event shall Company or any parties, organizations or entities associated with the corporate
            brand name
            or otherwise, mentioned at this website be liable for any damages whatsoever (including, without
            limitations, incidental and consequential damages, lost profits, or damage to computer hardware or
            loss of
            data information or business interruption) resulting from the use or inability to use the Website
            and the
            Website material, whether based on warranty, contract, tort, or any other legal theory, and whether
            or not,
            such organization or entities were advised of the possibility of such damages.
        </span>
        <span>
            <h2>Warranty Terms and Conditions</h2>
            Repair Gyani offers 1 months warranty on the majority of its repair services. Premium LCD screens
            are
            covered under 1 months warranty while only 15-Days warranty is provided on the High-copy LCD
            Screens.
            Batteries are also covered under the 3 month warranty scheme only.
            This Warranty covers the defects resulting from defective parts, materials or manufacturing if such
            defects
            are revealed during the period of 1 months or during the applicable warranty period, since the date
            of
            purchase.
            The device is considered defective if it fails to perform the functions as indicated in the
            operation
            manuals, technical specifications or any other similar documents supplied with the equipment, and
            the
            failure is due to internal device characteristics.
            The Warranty does not cover consumables or parts of limited regular functionality due to their
            natural wear
            and tear.
            We reserve the right to impose charges for services on the Product which are outside the cover of
            the
            warranty. We also reserve the right to decline to provide service where the Product is obsolete, no
            longer
            deemed serviceable or replaceable for any reason. Products after servicing or inspections must be
            collected
            within three (3) months from the date of notification. We may dispose of the Product which remains
            uncollected after three (3) months, without notice to You, in any way We deem fit and We may also
            claim any
            damages from You including any costs for the storage of the Product. We reserve the right of lien
            for unpaid
            charges.
        </span>
        <ol>
            <h2>LIMITATIONS OF COVERAGE – This warranty Does Not Cover:</h2>
            <li> Damage, fault or failure due to alteration or repairs made by anyone other than Us, or the use
                of
                supplies and accessories other than those manufactured by Us.</li>
            <li>Damage, fault or failure due to causes beyond Our control including, but not limited to, repairs
                necessary due to operator negligence, improper installation, damage caused by spillage of
                foods/liquids,
                wrong usage of electrical supply and voltage, abnormal voltage, excessive heat, dust, corrosive
                surroundings, chemical reaction, failure to maintain the Product, failure to operate or use the
                Product
                according to instructions, accident, mishandling, misuse, tampering, vandalism, theft, fire,
                lightning,
                flood, wind, freezing, power failure, static, normal wear and tear, pests, vermin, foreign
                matter entering
                the Product, inadequate or excess power supply, unusual atmospheric conditions, or acts of war
                or acts of
                God.</li>
            <li>Damage, fault or failure resulting from software, virus(es), electrical wiring and connections,
                user
                facilitated minor adjustments and settings, external antenna or local reception problems,
                structural
                problems of Your premises, inaccessible Products or parts, negligence, misuse, tampering or
                abuse, whether
                willful or not.</li>
            <li>Damage, fault or failure due to improper transportation, inappropriate storage conditions or
                materials,
                improper ventilation, reconfiguration of the Product, movement of the Product.</li>
            <li>Damage, fault or failure due to or arising out of, transit or delivery, packing, unpacking,
                assembly,
                installation, routine maintenance, dismantle, relocation or removal.</li>
        </ol>
        <div>
            <h2>Cancellation policy at Repair Gyani for the booking of mobile repair</h2>
            1. 100% amount will be refunded if the order is cancelled within 2 hours of booking.<br /><br />
            2. Rs 100 will be deducted if the cancellation happens after 2 hours of the booking and the device is yet to
            be
            picked up from customer end.<br /><br />
            3. No cancellation possible after the device pickup is done from customer end.
        </div>
    </fieldset>
</div>