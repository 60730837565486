import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-mobile-issues',
  templateUrl: './mobile-issues.component.html',
  styleUrls: ['./mobile-issues.component.scss']
})
export class MobileIssuesComponent {

  @Input() issuesForm: UntypedFormGroup;

  issues = [
    { id: 'myCheckbox1', for: 'myCheckbox1', icon: 'battery_alert', name: 'Battery Problem', value: 'Battery Problem' },
    { id: 'myCheckbox2', for: 'myCheckbox2', icon: 'vibration', name: 'Vibrator Diagnosis', value: 'Vibrator Diagnosis' },
    { id: 'myCheckbox3', for: 'myCheckbox3', icon: 'wifi', name: 'Wifi Diagnosis', value: 'Wifi Diagnosis' },
  ]
  
  onCheckboxChange(e) {
    const MobileIssue: UntypedFormArray = this.issuesForm.get('MobileIssue') as UntypedFormArray;
 
    if (e.target.checked) {
      MobileIssue.push(new UntypedFormControl(e.target.value));
    } else {
      let i: number = 0;
      MobileIssue.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          MobileIssue.removeAt(i);
          return;
        }
        i++;
      });
    }
    console.log(this.issuesForm.value)
  }
}