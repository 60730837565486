<div class="navbar" fxLayout fxLayoutAlign="space-around center" fxHide.lt-sm>
  <img src='assets/logo/logo.png'  alt="Repairing Bazaar" [routerLink]="['/login']"/>
  <ng-container *ngTemplateOutlet="searchProducts;context: {searchInputWidth:'60%'}"></ng-container>
  <ng-container *ngTemplateOutlet="searchBarActions"></ng-container>
</div>
<div fxLayout="column" class="mobile-header" fxHide.sm fxHide.gt-sm>
  <div fxLayout fxLayoutAlign="space-between center">
    <img src='assets/logo/logo.png'  width="130px" height="80px" alt="Repairing Bazaar" [routerLink]="['/login']"/>
    <ng-container *ngTemplateOutlet="searchBarActions"></ng-container>
  </div>
  <div fxFlex fxLayoutAlign="center">
    <ng-container *ngTemplateOutlet="searchProducts;context: {searchInputWidth:'90%'}"></ng-container>
  </div>
</div>

<ng-template #searchProducts let-searchInputWidth="searchInputWidth">
  <div fxLayout class="search-block" fxFlex="{{searchInputWidth}}">
    <input matInput type="text" placeholder="Search products..." aria-label="Search products"
      [formControl]="searchControl" [matAutocomplete]="auto" fxFlex>
    <mat-icon style="margin-top: 5px;">search</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngIf="(filteredProducts | async)?.length==0 && filterValue.length>2">
        <span [style.color]="'#e71c37'">No Search Found</span>
      </mat-option>
      <mat-option *ngFor="let data of filteredProducts | async"
        [value]="data.product_name+' '+ data.option_value+' '+ data.option_unit">
        <div><img src='assets/img/a.jpg' width="30px">&nbsp;&nbsp;{{data.product_name}} {{data.option_value}}
          {{data.option_unit}}</div>
      </mat-option>
    </mat-autocomplete>
  </div>
</ng-template>

<ng-template #searchBarActions>
  <div fxLayout fxLayoutAlign="space-around center" fxLayoutGap="10px" class="search-bar-buttons">
    <button mat-icon-button class="notification-shopping_cart-icon" #notificationToggleButton (click)="notification()">
      <mat-icon matBadge="4" matBadgeOverlap="true" matBadgePosition="above after" matBadgeColor="accent"
        matBadgeSize="small">notification_important</mat-icon>
    </button>
    <div #notificationMenu class="cart-notification-container">
      <div *ngIf="isnotify">
        <div fxLayout class="title">
          <div [style.font-size.px]="18" fxFlex>
            Notifications
          </div>
          <a href="#" class="delete-notification">Delete All</a>
        </div>
        <div *ngFor="let item of notification_data" class="notification-content">
          <hr>
          {{item.data}}
        </div>
      </div>
    </div>
    <button mat-stroked-button fxLayout class="notification-shopping_cart-icon" #cartToggleButton (click)="cart()">
      <mat-icon matBadge="2" matBadgeOverlap="true" matBadgePosition="above after" matBadgeColor="accent"
        matBadgeSize="small">shopping_cart</mat-icon><span [ngStyle]="{'margin-left.px':'6'}">Cart</span>
    </button>
    <div #cartMenu class="cart-notification-container">
      <div *ngIf="iscart">
        <div *ngIf='badge==0; else elseblock' class="empty-cart-content">
          You have not added any products to Cart
        </div>
        <ng-template #elseblock>
          <div fxLayout class="title" fxLayoutAlign="space-between">
            <div [style.font-size.px]="18">
              Current Order
            </div>
            <button class="clear-button" (click)="clear()">Clear</button>
          </div>
          <div *ngIf='clearCartScreen' class="cart-content">
            <ul *ngFor="let data of cartData">
              <a href="#" [ngStyle]="{'color':'black'}">
                <div fxLayoutAlign="space-between" class="cart-item">
                  <li>{{data.name}}</li>
                  <div>{{data.qty}}</div>
                </div>
              </a>
            </ul>
          </div>
          <button class="cart-button">View Shopping Cart</button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>