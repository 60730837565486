<div class="container">
    <h3>My WhishList</h3>
    <mat-grid-list cols="{{cols | async}}" gutterSize="5px" rowHeight="400px">
        <mat-grid-tile *ngFor="let data of groceryCarousel" [colspan]="colspan" [rowspan]="rowspan">
            <div fxLayout="column" class="card-container">
                <div fxLayoutAlign="end center">
                    <button mat-icon-button (click)="onDelete(i)" [disableRipple]="true">
                        <mat-icon>highlight_off</mat-icon>
                    </button>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <a href="{{data.linkToPage}}"><img src="{{data.imageSource}}" alt="{{data.productName}}"
                            class="image-size" [ngStyle.lt-md]="{'width.px':'150','height.px':'150'}"></a>
                    <p title="{{data.productName}}">{{data.productName}}</p>
                </div>
                <div class="item-price">
                    <span class="current-price">{{data.currentPrice}}</span>
                    &nbsp;
                    <span><del>{{data.markedPrice}}</del></span>
                    <br>
                    <span>{{data.save}}</span>
                </div>
                <div fxLayoutAlign="center" class="move-to-bag">
                    <button aria-label="move-to-bag button" mat-flat-button
                        [disableRipple]="true">{{data.cartStatus}}</button>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>