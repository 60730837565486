import { Component } from '@angular/core';
import { IcustomerMarketing } from '../../models/customer-attractiveness-strategy.model';

@Component({
  selector: 'app-customer-attractiveness-strategy',
  templateUrl: './customer-attractiveness-strategy.component.html',
  styleUrls: ['./customer-attractiveness-strategy.component.scss']
})

export class CustomerAttractivenessStrategyComponent {

  customerMarketing: IcustomerMarketing[] = [
    { imageSource: "assets/home/sertificate-01.png", heading: 'We Value Your Time', content: 'Our tech support saves your time and energy and lets you enjoy your computer to the max.' },
    { imageSource: "assets/home/sertificate-02.png", heading: 'Remote, Hyper-secure Connection', content: 'You select the service you need, and our tech support does all the work through a remote, hyper-secure connection.' },
    { imageSource: "assets/home/sertificate-03.png", heading: 'Customer Happiness', content: 'Your satisfaction is our #1 priority. We pledge to be by your side until you are satisfied with our services.' }
  ]

}
