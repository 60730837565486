import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { IAddress, IEstimatedDeliveryAddress } from '../../models/checkout.model';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss']
})

export class CheckoutAddressComponent {
  hidden: boolean;
  openViewDetails: boolean = false;
  selectedAddress = 0;
  showDeleteDialog: string = '';
  show: boolean;
  ViewDetails: string = "View Details";

  constructor(public dialog: MatDialog) { }
  addressList: IAddress[] = [
    { name: 'Nidhi Gupta', place: 'Home', address: 'Shroti Wali Gali', locality: 'Jaiganj', city: 'Aligarh', state: 'UP', pincode: 202001, mobile_no: 9643675575 }
  ]

  checkoutAddress: IEstimatedDeliveryAddress[] = [
    { img: 'assets/shirt.jpg', date: '30 July 2020' },
    { img: 'assets/socks.jpg', date: '30 July 2020' },
    { img: 'assets/socks.jpg', date: '30 July 2020' }
  ]

  order: number = 3000
  bagtotal: number = 5055;
  bagdiscount: number = 1200;
  coupondiscount: number = 855;

  toggle() {
    this.hidden = !this.hidden;

    if (this.hidden) {
      this.ViewDetails = 'Hide Details';
    }

    if (!this.hidden) {
      this.ViewDetails = 'Show Details';
    }
  }

  onSelctedAddress(data, i) {
    console.log({ data, i });
    this.selectedAddress = i;
  }

}