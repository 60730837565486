<!-- <app-header></app-header>
<app-home-page-slider></app-home-page-slider>
<app-repairing-by-category></app-repairing-by-category>
<app-search-mobile-model></app-search-mobile-model>
<app-phone-models-layout></app-phone-models-layout>
<app-footer></app-footer> -->
<!-- <app-smartphone></app-smartphone> -->
<!-- <app-home-page-about></app-home-page-about> -->
<!-- <app-mobile-repair></app-mobile-repair> -->
<!-- <app-terms-and-conditions></app-terms-and-conditions>
<app-privacy-and-policy></app-privacy-and-policy>
<app-shipping-policy></app-shipping-policy> -->
<!-- <app-about-us></app-about-us> -->
<app-header></app-header>
<app-home-page-slider></app-home-page-slider>
<router-outlet></router-outlet>
<app-repairing-services></app-repairing-services>
<app-hows-it-work></app-hows-it-work>
<app-repairing-bazar-about></app-repairing-bazar-about>
<app-footer></app-footer>
<!-- <app-checkout-bag></app-checkout-bag> -->
<!-- <app-login></app-login> -->