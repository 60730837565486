<div fxLayoutAlign="center center" fxLayout="column" class="repair-gyani">
    <h2>Repair Gyani is the India’s No. 1 Mobile Phone Repairing Center</h2>
    <div class="content-container" [ngStyle.xs]="{'text-align':'justify'}">Repair Gyani is the largest mobile phone
        repairing chain in India, also the biggest electronic service provider. We are a one-stop solution for all needs
        related to the repair of android & IOS smartphones
        and Windows based laptops. The company aims to solve the problems in the repairing industry. Repair Gyani offers
        repairs and services for smartphones, mobiles, and laptops of all major brands,
        including Apple, iPhone, OnePlus, Redmi, Mi, Oppo, Vivo, Xiaomi, Google Pixel, Samsung and Realme phone. Repair
        Gyani has more than 10 repairing stores and service work shops across India and
        the numbers are rapidly increasing.<br /><br />
        At Repair Gyani, we serve all our customers across India with best in the mobile repairing sector at most
        affordable prices. We provide certified service partners for major branded phones. We provide
        quality smartphone repairs with 100% customer satisfaction. Our doorstep mobile repair stores are available
        across all over India.<br /><br />
        We provide wide range of mobile phone services as online mobile repair, doorstep mobile repair, you can find
        mobile repair store/shop near your local area. We also have refubrished mobile stores where
        you can get branded mobile phones at half the price with 6 month service warranty.<br /><br />
        If you have a Smartphone & Laptop, well, considering today's world, you sure have it, then you have reached at
        the right place! Repair Gyani redefines the word 'fixing' by making it more easy, effective,
        efficient and within the reach of all the mortals, who thought getting a phone or a laptop repaired might be the
        worst nightmare of their lives.</div>
</div>