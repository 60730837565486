<div fxLayout="column" fxLayoutGap="30px" [ngStyle.lt-sm]="{'text-align':'justify'}">
    <div class="image-container">
        <img src="assets/aboutUs/banner.png" class="image">
    </div>
    <div class="logo-container">
        <img src="assets/aboutUs/logo.jpg" width="152px" height="152px" class="logo">
    </div>
    <div fxLayout="column" class="aboutus">
        <div class="align"><span>Repair Gyani, the most trusted and reliable mobile phone repair and spare part
                provider,offers world-class smartphone repairing services for all leading phone brands across UP through
                online and offline channels.Don’t be heartbroken for your smartphone damage!! We are here to fix
                it!!</span><br /><br />
            <span>Want to discover how? Go get rolling below…</span>
            <img src="" alt="" />
        </div>

        <div fxLayout fxLayout.xs="column" fxLayoutAlign="space-between">
            <div *ngFor="let data of introduction" fxFlex.gt-xs="45%">
                <h2 class="heading">{{data.heading}}</h2>
                <p class="intro">{{data.intro}}</p>
            </div>
        </div>
    </div>
    <div class="about-team">
        <h3 class="our-products">Professionally Managed & Organized Setup</h3>
        <span>A team of ambitious founders, qualified professionals, trustworthy and trained repairmen and hassle-free
            online system, we have worked out a perfect combination to provide you with mobile repair with utmost care
            and precision.</span>
    </div>
    <div fxLayout fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="20px"
        fxLayoutAlign="space-between" class="feature">
        <div fxLayout="column" fxLayoutGap="30px" fxFlex.gt-xs="40%">

            <div fxLayout fxLayoutGap="30px" *ngFor="let data of feature1"
                [ngStyle.gt-sm]="{'justify-content': 'center'}">
                <mat-icon>{{data.icon}}</mat-icon>
                <div fxLayout="column">
                    <strong>{{data.feature}}</strong>
                    <span>{{data.about}}</span>
                </div>
            </div>
        </div>
        <div fxHide.lt-md class="image-container">
            <img src="assets/aboutUs/quality.png">
        </div>
        <div fxLayout="column" fxLayoutGap="30px" fxFlex="40%">
            <div fxLayout fxLayoutGap="30px" *ngFor="let data of feature2"
                [ngStyle.gt-sm]="{'justify-content': 'center'}">
                <mat-icon>{{data.icon}}</mat-icon>
                <div fxLayout="column">
                    <strong>{{data.feature}}</strong>
                    <span>{{data.about}}</span>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="team">
        <div>
            <h3 class="our-team">Our Team
            </h3>
            <h1 class="best-team">We Are The Best Team</h1>
        </div>
        <div fxLayoutAlign="center center" fxLayout fxLayout.lt-md="column">

            <div fxLayout="column" class="image-container" *ngFor="let data of team">
                <div class="item">
                    <img src="{{data.image}}" class="image1">
                </div>
                <div class="item">
                    <strong class="employee-name">{{data.name}}</strong>
                    <p class="employee-designation">{{data.designation}}</p>
                </div>
            </div>
        </div>
    </div>
</div>