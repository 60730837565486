import { Component } from '@angular/core';

import { Ifeature, Iintroduction, Iteam } from '../models/about-us.model'

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})

export class AboutUsComponent {

  team: Iteam[] = [
    { image: 'assets/aboutUs/about 1.png', name: 'Nidhi Gupta', designation: 'Developer' },
    { image: 'assets/aboutUs/about 1.png', name: 'Nidhi Gupta', designation: 'Developer' },
    { image: 'assets/aboutUs/about 1.png', name: 'Nidhi Gupta', designation: 'Developer' },
    { image: 'assets/aboutUs/about 1.png', name: 'Nidhi Gupta', designation: 'Developer' }
  ];

  introduction: Iintroduction[] = [
    { heading: 'Who We Are', intro: 'Repair Gyani - Your one-stop destination to make your non-working phones working again. A network of offline service stations and a vibrant online mobile repair shop, offering a multitude of genuine mobile repair services & high-quality spare parts. We are one of the best in the industry with proficiency in repairing phones of every brand, make and model.' },
    { heading: 'How We operate', intro: 'We serve you in your preferred way no matter where you live. Our repair technicians are at your service 7 days a week. You can opt for our onsite repair services if you are in Aligarh or can avail door-to-door pick and drop services if in any other part of the City. While onsite repair would mean repairing it on the spot (subject to the condition of the phone), door-to-door would mean picking up the phone from your location and delivering it back after repair.' }
  ];

  feature1: Ifeature[] = [
    { icon: 'filter_vintage', feature: '1 Months Warranty', about: 'We use only quality genuine parts; hence, offer a no-fuss up to 1 months repair warranty on every repair, except for Batteries and High Quality LCD Screens which are covered under 3 month warranty.' },
    { icon: 'filter_vintage', feature: 'Free Pickup & Drop', about: 'We provide pickup & drop services without charging anything. Just place your order through a call/mail/ portal and we will be there to collect your damaged phone.' },
    { icon: 'filter_vintage', feature: '365 Days with you!', about: 'Whether it’s a weekday or a weekend, day or night, summer or winter; we are at your service all through.' },
    { icon: 'filter_vintage', feature: 'Quick Repair Services', about: 'We understand how important the phone is to you personally and professionally. So, we adhere to the promised timelines strictly.' }
  ];

  feature2: Ifeature[] = [
    { icon: 'filter_vintage', feature: 'Need A Spare Phone', about: 'Located in Aligarh? We can arrange a spare phone for you till we repair to ensure your everyday life isn’t interrupted in any way.' },
    { icon: 'filter_vintage', feature: 'Pay If Satisfied', about: 'Not satisfied with any of our product/services? Get your money back through our 10 days money back guarantee feature.' },
    { icon: 'filter_vintage', feature: 'Competitive Rates', about: 'Our endeavour is to offer quality service experience for the widest portfolio of brands like Samsung, Apple, Xiaomi, One Plus, LG, Sony, HTC, Lenovo, Gionee, Asus, LeTv, Blackberry, OPPO, Vivo or Motorola at justified rates.' },
    { icon: 'filter_vintage', feature: 'Honesty & Transparency', about: 'We execute any action only after the customer’s consent & All the payments done online are safe and secured' }
  ];
}