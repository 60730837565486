<div [formGroup]="issuesForm">
    <form formArrayName="MobileIssue" >
        <h2>Select The Issue Type</h2>
        <ul>
            <li *ngFor="let issue of issues">
                <input type="checkbox" id="{{issue.id}}" [value]="issue.value" (change)="onCheckboxChange($event)" />
                <label for="{{issue.for}}" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>{{issue.icon}}</mat-icon>
                    <span>{{issue.name}}</span>
                </label>
            </li>
        </ul>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center">
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
    </form>
</div>