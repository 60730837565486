<div fxLayout="column" fxLayoutGap="10px">
    <h2>Device Repair & Replacement</h2>
    breadcrumb
    <div fxLayout="column" fxFlex>
        <img src="assets/slider/Chocolates.jpg" alt="" />
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
        <mat-form-field appearance="outline">
            <mat-label>Select Brand</mat-label>
            <select matNativeControl [(ngModel)]="brandValue" name="brandValue">
                <option [value]="data.brand" *ngFor="let data of selectBrand">{{data.brand}}</option>
            </select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf=brandValue>
            <mat-label>Select Model</mat-label>
            <select matNativeControl [(ngModel)]="modelValue" name="modelValue">
                <option [value]="data.model" *ngFor="let data of selectModel">{{data.model}}</option>
            </select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf=modelValue>
            <mat-label>Service</mat-label>
            <select matNativeControl name="serviceValue" [(ngModel)]="serviceValue">
                <option [value]="data.service" *ngFor="let data of service">{{data.service}}</option>
            </select>
        </mat-form-field>
    </div>
</div>