import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-mobile-model',
  templateUrl: './search-mobile-model.component.html',
  styleUrls: ['./search-mobile-model.component.scss']
})

export class SearchMobileModelComponent {

  value = '';
}