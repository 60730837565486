import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/common/modules/material.module';
import { AboutUsComponent } from '../pages/about-us.component';

@NgModule({
    declarations: [
        AboutUsComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        AboutUsComponent
    ]
})

export class AboutUsModule { }