import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mobile-repair',
  templateUrl: './mobile-repair.component.html',
  styleUrls: ['./mobile-repair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MobileRepairComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private toastr: ToastrService) { }
  isLinear = true;
  registrationForm: UntypedFormGroup;
  stepper: MatStepper;
  ngOnInit() {
    this.registrationForm = new UntypedFormGroup({
      MobileNoGroup: this.fb.group({
        MobileNoCtrl: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      }),

      MobileBrandGroup: this.fb.group({
        MobileBrandCtrl: ['']
      }),

      MobileModelGroup: this.fb.group({
        MobileModelCtrl: ['']
      }),

      MobileIssue: this.fb.array([], [Validators.required]),

      MobileDetailsGroup: this.fb.group({
        fullName: ['', [Validators.required, Validators.maxLength(25), Validators.pattern("[A-Za-z ]{2,}")]],
        emailId: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.required])],
        aadharCard: ['', [Validators.required, Validators.pattern('^[2-9][0-9]{11}$')]],
        IMEINumber: ['', [Validators.required,Validators.pattern('^(86|35|01)[0-9]{13}$')]],
        pinCode: ['', [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')]],
        phoneNo: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
        state: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]*$')]],
        city: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]*$')]],
        addressLine1: ['', [Validators.required, Validators.minLength(5)]],
        addressLine2: ['', [Validators.minLength(5)]],
        landmark: ['', Validators.required]
      }),

      PlaceOrderGroup: this.fb.group({
        PlaceOrderCtrl: ['', Validators.required]
      }),

      Accessory: this.fb.array([], [Validators.required]),

    })
  }

  // showSuccess() {
  //   this.toastr.success('You have successfully booked an appointment with us', '', {
  //     timeOut: 200000
  //   });
  //   this.stepper.reset();
  // }
}