import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IrepairingByCategoryItems } from '../../models/repairing-by-category.model';

@Component({
  selector: 'app-repairing-by-category',
  templateUrl: './repairing-by-category.component.html',
  styleUrls: ['./repairing-by-category.component.scss']
})

export class RepairingByCategoryComponent implements OnInit {

  public isCarouselVisible: boolean = false;

  @Input()
  public isNavinCenter: boolean = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.isCarouselVisible = true;
    }, 0);
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      218: {
        items: 2
      },
      328: {
        items: 3
      },
      450: {
        items: 4
      },
      572: {
        items: 5
      },
      694: {
        items: 6
      },
      816: {
        items: 7
      },
      938: {
        items: 8
      },
      1060: {
        items: 9
      }
    },
    nav: true
  }
  public repairingCategory: IrepairingByCategoryItems[] = [
    {
      imageSource: "",
      // altText: "Grocery & Staples",
      text: "SmartPhone",
    },
    {
      imageSource: "",
      // altText: "Household Items",
      text: "RO",
    },
    {
      imageSource: "",
      // altText: "Personal Care",
      text: "AC",
    },
    {
      imageSource: "",
      // altText: "Beverages",
      text: "Refrigerator",
    },
    {
      imageSource: "",
      // altText: "Biscuits, Snacks & Chocolates",
      text: "TV/LED",
    },
    {
      imageSource: "",
      // altText: "Pet Care",
      text: "Washing Machine",
    }
  ];
}