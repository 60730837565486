import { Component } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { IphoneModel } from '../../models/phone-model-layout.model';

@Component({
  selector: 'app-phone-models-layout',
  templateUrl: './phone-models-layout.component.html',
  styleUrls: ['./phone-models-layout.component.scss']
})

export class PhoneModelsLayoutComponent {

  public cols: Observable<number>;
  public colspan: number;
  public rowspan: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {

    this.cols = this.getColsByBreakpoint();
    this.colspan = 1;
    this.rowspan = 1;
  };
  public services: IphoneModel[] = [
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' },
    { image: '', href: '' }
  ];

  private getColsByBreakpoint() {
    return this.breakpointObserver.observe([
      '(max-width: 359px)',
      '(max-width: 517px)',
      Breakpoints.XSmall,
      '(max-width: 730px)',
      Breakpoints.Small,
      '(max-width: 1100px)',
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).pipe(
      map((result) => {
        if (result.breakpoints['(max-width: 359px)']) { return 2; }
        if (result.breakpoints['(max-width: 517px)']) { return 3; }
        if (result.breakpoints[Breakpoints.XSmall]) { return 2; }
        if (result.breakpoints['(max-width: 730px)']) { return 2; }
        if (result.breakpoints[Breakpoints.Small]) { return 3; }
        if (result.breakpoints['(max-width: 1100px)']) { return 4; }
        if (result.breakpoints[Breakpoints.Medium]) { return 4; }
        if (result.breakpoints[Breakpoints.Large]) { return 4; }
        if (result.breakpoints[Breakpoints.XLarge]) { return 4; }
      })
    );
  }
}