import { Component } from '@angular/core';

@Component({
  selector: 'app-smartphone',
  templateUrl: './smartphone.component.html',
  styleUrls: ['./smartphone.component.scss']
})

export class SmartphoneComponent {

  brandValue: any;
  modelValue: any;
  serviceValue: any;

  selectBrand = [
    { brand: 'Apple' }, { brand: 'Samsung' }, { brand: 'Oppo' }
  ];

  selectModel = [
    { model: 'A560' }, { model: 'Galaxy 3211' }, { model: 'Note 2 pro' }
  ];

  service = [
    { service: 'Software' }, { service: 'Hardware' }, { service: 'Dead Phone' }
  ];
}