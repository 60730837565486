<div fxLayout="column" fxLayoutGap="30px" class="footer-container">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
        <div style="text-align: center"><img src="assets/footer/logo.png" height="80px"
                [routerLink]="['/login']"></div>
        <div *ngFor="let item of items" [ngStyle.gt-sm]="{'width': '30%'}">
            {{item}}
        </div>
        <div *ngFor="let data of footer">
            <ul><strong>{{data.heading}}</strong>
                <li *ngFor="let child of data.children">
                    <span [routerLink]="child.navigationLink">{{child.listItem}}</span>
                </li>
            </ul>
        </div>
        <div>
            <strong>Registered Office Address</strong>
            <div fxLayout="column" fxLayoutGap="9px" class="registered-office">
                <div>
                    18/49, Sarai Bala, Jaiganj<br>
                    Aligarh, Uttar Pradesh 202001</div>
                <div> Email: repairgyani@gmail.com </div>
                <div> Telephone: 8171818786</div>
            </div>
        </div>
    </div>

    <div fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutGap.lt-md="15px" fxLayout
        fxLayoutAlign.lt-sm="space-between">
        <div fxLayout="row wrap" fxLayoutGap="5px">
            <span class="image-container" fxLayoutAlign="center center" *ngFor="let data of images">
                <img src="{{data.image}}" width="50px" height="30px">
            </span>
        </div>
        <div fxLayout fxFlex.xs fxLayoutGap="15px">
            <mat-icon svgIcon="facebook"></mat-icon>
            <mat-icon svgIcon="instagram"></mat-icon>
            <mat-icon svgIcon="linkedin"></mat-icon>
            <mat-icon svgIcon="twitter"></mat-icon>
        </div>
    </div>

</div>
<div class="rpair-gyani-rights">
    &nbsp;<span>Repair Gyani - All Right Reserved <span>&#169;</span>2022</span>
</div>