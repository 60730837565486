import { Component } from '@angular/core';

import { DomSanitizer } from "@angular/platform-browser";
import { Ifooter, Iimage } from '../../models/footer.model';
import { MatIconRegistry } from "@angular/material/icon";
import { APP_PATH } from 'src/app/routes';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  footer: Ifooter[] = [
    {
      heading: 'Information',
      children: [
        { listItem: 'About Us', navigationLink: APP_PATH.aboutUs },
        { listItem: 'FAQ' },
        { listItem: 'Privacy Policy', navigationLink: APP_PATH.privacyPolicy },
        { listItem: 'Terms Of Use', navigationLink: APP_PATH.termsandconditions },
        { listItem: 'Shipping Policy', navigationLink: APP_PATH.shippingPolicy },
        { listItem: 'My Account' },
        { listItem: 'Contact Us' }],
    },
    {
      heading: 'Categories',
      children: [
        { listItem: 'Apple ' },
        { listItem: 'Vivo' },
        { listItem: 'Oppo' },
        { listItem: 'Oneplus' },
        { listItem: 'Nokia' },
        { listItem: 'Samsung' },
        { listItem: 'Techno' }
      ]
    }
  ];

  private customIcons: Array<[string, string]> = [
    ["facebook", "assets/footer/facebook-square.svg"],
    ["linkedin", "assets/footer/linkedin.svg"],
    ["twitter", "assets/footer/twitter-square.svg"],
    ["instagram", "assets/footer/instagram-square.svg"]
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.customIcons.forEach(([iconName, iconPath]) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(iconPath)
      );
    });
  }

  items = ['Repair Gyani, the most trusted and reliable mobile phone repair and spare part provider, offers world-class smartphone repairing services for all leading phone brands across UP through online and offline channels. Don’t be heartbroken for your smartphone damage!! We are here to fix it!!'];

  public images: Iimage[] = [
    { image: 'assets/footer/phonepe.png' },
    { image: 'assets/footer/paytm.png' },
    { image: 'assets/footer/bhim.png' },
    { image: 'assets/footer/amazon.jpg' },
    { image: 'assets/footer/americanexpress.PNG' },
    { image: 'assets/footer/rupay.PNG' },
    { image: 'assets/footer/visa.png' }
  ];
}