<div [formGroup]="accessoryForm">
    <form formArrayName="Accessory">
        <h2>Accessories Included</h2>
        <div fxLayout fxLayoutGap.gt-xs="30px" fxLayoutAlign="center center">
            <div *ngFor="let data of accessoryIncluded">
                <input type="checkbox" [value]="data.value" (change)="onCheckboxChange($event)" />
                <span>{{data.name}}</span>
            </div>
        </div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center" class="mobile-accessory">
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
            <button mat-raised-button matStepperNext color="primary">Next</button>
        </div>
    </form>
</div>