import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutUsComponent } from './client/about-us/pages/about-us.component';
import { LoginComponent } from './client/authentication/components/login/login.component';
import { MobileRepairComponent } from './client/mobile-repair/pages/mobile-repair.component';
import { FooterComponent } from './common/components/footer/footer.component';
import { PrivacyAndPolicyComponent } from './common/components/privacy-and-policy/privacy-and-policy.component';
import { ShippingPolicyComponent } from './common/components/shipping-policy/shipping-policy.component';
import { TermsAndConditionsComponent } from './common/components/terms-and-conditions/terms-and-conditions.component';

import { APP_PATH } from './routes';

const routes: Routes = [
  { path: APP_PATH.aboutUs, component: AboutUsComponent },
  { path: APP_PATH.footer, component: FooterComponent },
  { path: APP_PATH.login, component: LoginComponent },
  { path: APP_PATH.termsandconditions, component: TermsAndConditionsComponent },
  { path: APP_PATH.shippingPolicy, component: ShippingPolicyComponent },
  { path: APP_PATH.privacyPolicy, component: PrivacyAndPolicyComponent },
  { path: APP_PATH.repairingBazar, component: MobileRepairComponent },
  { path: '', pathMatch: 'full', redirectTo: APP_PATH.login},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }