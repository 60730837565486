import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';

import { FooterComponent } from '../components/footer/footer.component';
import { ReadMoreModule } from './readmore.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReadMoreModule,
    RouterModule
  ],
  exports: [
    FooterComponent,
    ReadMoreModule
  ]
})

export class FooterModule { }