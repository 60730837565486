import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { IProductCarousel } from 'src/app/common/models/product-carousel.model';
import { map } from 'rxjs/operators';

export interface IMenuItems {
  icon: string;
  title: string;
  link: string;
}

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent {

  public cols: Observable<number>;
  public colspan: number;
  public rowspan: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {

    this.cols = this.getColsByBreakpoint();
    this.colspan = 1;
    this.rowspan = 1;
  };

  public tiles: any;

  public groceryCarousel: IProductCarousel[] = [
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    },
    {
      imageSource: "",
      productName: "Pampers Pants S 105S",
      currentPrice: "Rs. 1,099.00",
      markedPrice: "Rs. 1,249",
      save: "(Save Rs. 150.00)",
      cartStatus: "Move To Bag",
      linkToPage: '#'
    }
  ];
  private getColsByBreakpoint() {
    return this.breakpointObserver.observe([
      '(max-width: 380px)',
      '(max-width: 547px)',
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).pipe(
      map((result) => {
        if (result.breakpoints['(max-width: 380px)']) { return 1; }
        if (result.breakpoints['(max-width: 547px)']) { return 2; }
        if (result.breakpoints[Breakpoints.XSmall]) { return 3; }
        if (result.breakpoints[Breakpoints.Small]) { return 3; }
        if (result.breakpoints[Breakpoints.Medium]) { return 4; }
        if (result.breakpoints[Breakpoints.Large]) { return 5; }
        if (result.breakpoints[Breakpoints.XLarge]) { return 5; }
      })
    );
  }
  public onDelete(index: number) {
    this.groceryCarousel.splice(index, 1);
  }
}