<div class="shipping-policy">
    <fieldset>
        <legend><h2>Shipping</h2></legend>
        <ul fxLayout="column" fxLayoutGap="10px">
            <div>
                <h2>When taking repair service:</h2>
                <li>After confirmation from your end, our executive will pick the device from your provided address, at
                    the
                    time convenient for you. We recommend packing the device in a hard box. If you want, we can ship
                    packing
                    material to your home. The only concern behind doing this is ensuring safety of the device without
                    any
                    damage. Upon receiving the device, we will fix it and send it back to you.</li>
                <li>For Pan India – Free pick up and drop service is available. Once we get the faulty device, we will
                    repair it and deliver it back to you without charging any cost.</li>
                <li>ETA (Estimated Time of Arrival) for pickup is 1 business days.</li>
                <li>Total ETA (Estimated Time of Arrival) to deliver back the device is 1-3 business days from the time
                    of
                    receiving your product.</li>
                <li>We don’t take any additional charges for pick/drop, courier service or device delivery. You will
                    only
                    pay the price you see on the website.</li>
            </div>
            <div>
                <h2>Note: For Aligarh, is 1-2 business days.</h2>
                <li>We don’t take any additional charges for pick/drop, courier service or device delivery. You will
                    only
                    pay the price you see on the website.</li>
            </div>
            <div>
                <h2>When ordering spare parts:</h2>
                <li>After confirmation from your end, device will be delivered to you by our trusted courier partner.
                </li>
            </div>
        </ul>
    </fieldset>
</div>