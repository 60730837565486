<div [formGroup]="brandForm">
    <form formGroupName="MobileBrandGroup" fxLayout="column">
        <div fxLayoutAlign="center center" fxLayout="column">
            <h2>Select The Brand Of Your SmartPhone</h2>
            <mat-form-field appearance="outline" >
                <input matInput formControlName="MobileBrandCtrl"name="search" [(ngModel)]="searchText" autocomplete placeholder="&#61442;  Search Brand" >
            </mat-form-field>
        </div>
        <div class="gyani" [ngStyle.xs]="{'padding':'10px'}" fxFlex>
            <mat-grid-list cols="{{cols | async}}" gutterSize="10" rowHeight="110">
                <mat-grid-tile *ngFor="let data of services | filter:searchText" [colspan]="colspan" [rowspan]="rowspan" (click)="goToNext()">
                    <a class="image-container">
                        <img src="{{data.image}}"></a>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div>
            <button mat-raised-button matStepperPrevious color="warn">Back</button>
        </div>
    </form>
</div>