import { Component, Renderer2, ElementRef, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Observable } from 'rxjs';
import { IProduct, Icart } from '../models/header.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  public badge: number;
  public clearCartScreen: boolean = true;
  public filteredProducts: Observable<IProduct[]>;
  public filterValue: string = '';
  public iscart = false;
  public isnotify = false;
  public notify: boolean;
  public noDataFound: string;
  public products: IProduct[];
  public searchControl = new UntypedFormControl();

  constructor(private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e && this.notificationToggleButton && !this.findElement(this.notificationToggleButton, e) && !this.notificationMenu.nativeElement.contains(e.target)) {
        this.isnotify = false;
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e && this.cartToggleButton && !this.findElement(this.cartToggleButton, e) && !this.cartMenu.nativeElement.contains(e.target)) {
        this.iscart = false;
      }
    });
  }

  private findElement(notificationToggleButton: QueryList<MatButton>, event: Event) {
    let buttonList: MatButton[] = notificationToggleButton && notificationToggleButton.toArray();
    if (buttonList && buttonList.length > 0) {
      const ifContains: boolean = buttonList.some(button => {
        if (button._elementRef
          && button._elementRef.nativeElement
          && button._elementRef.nativeElement.contains(event.target))
          return true;
      });
      return ifContains;
    }
  }
  ngAfterViewInit(): void {
    this.notificationToggleButton.changes.subscribe(val => {
      //console.log(this.notificationToggleButton.toArray());
    });

  }

  @ViewChildren('notificationToggleButton') notificationToggleButton: QueryList<MatButton>;
  @ViewChild('notificationMenu') notificationMenu: ElementRef;
  @ViewChildren('cartToggleButton') cartToggleButton: QueryList<MatButton>;
  @ViewChild('cartMenu') cartMenu: ElementRef;

  private _filter(value: string): IProduct[] {
    if (value) {
      this.filterValue = value.toLowerCase();
      if (this.filterValue.length > 2) {
        return this.products.filter(data => data.product_name.toLowerCase().includes(this.filterValue));
      }
    }
    return [];
  }

  cart() {
    this.iscart = !this.iscart;
    this.badge = 1;
  }

  clear() {
    this.clearCartScreen = false;
  }

  notification() {
    this.isnotify = !this.isnotify;
  }

  cartData: Icart[] = [
    {
      name: 'baby soap',
      qty: 2
    },
    {
      name: 'baby oil',
      qty: 1
    },
    {
      name: 'baby t-shit',
      qty: 4
    },
    {
      name: 'baby cream',
      qty: 1
    }
  ];

  notification_data: any[] = [
    { data: 'notification1' },
    { data: 'notification2' },
    { data: 'notification3' }
  ];
}