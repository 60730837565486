import { Component, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { IImageSlider } from '../../../common/models/image-slider.model';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})

export class ImageSliderComponent {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
    },
    nav: false
  }

  @Input()
  public slider: Array<IImageSlider> = []
}