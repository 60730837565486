import { Component } from '@angular/core';
import { Iservice } from '../../models/repairing-services.model';

@Component({
  selector: 'app-repairing-services',
  templateUrl: './repairing-services.component.html',
  styleUrls: ['./repairing-services.component.scss']
})

export class RepairingServicesComponent {
  service1: Iservice[] = [
    { heading: 'All Brands & Models', content: 'We repair all mobile & laptop brands & models!' },
    { heading: '1 Day Service', content: 'We repair devices in just 1 day!' },
    { heading: 'Skilled Technicians', content: 'Certified professional team repair your device!' }
  ];
  service2: Iservice[] = [
    { heading: 'Genuine Parts', content: 'We provide quality & original products only!' },
    { heading: 'Data Security', content: 'Do not worry. Your data is safe with us!' },
    { heading: '90 days Warrenty', content: 'Genuine products with a warrenty of 3 months!' }
  ];
}
