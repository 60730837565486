<div fxLayout="column" class="main-container">
<div style="padding:20px" fxLayout="column">
    <h1 style="text-align: center;">How it works at Repair Gyani Service Center?</h1>
    <div fxLayout fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.xs="column" fxFlex.xs>
        <div class="try"  [class.isActive]="showDiv.repairOnline"
            (click)="showDiv.repairOnline = true;showDiv.visitStore = false;">Repair Online
            <br />
            At the comfort of the home
        </div>
        <div class="try" [class.isActive]="showDiv.visitStore"
            (click)="showDiv.visitStore = true;showDiv.repairOnline = false;">Visit Store <br />
            Walk into our store
        </div>
    </div>
</div>
<div *ngIf="showDiv.repairOnline" style="color: white;">
    <div style="text-align: center;" fxLayout fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <div *ngFor="let data of howsItWork2" fxFlex.gt-sm="15" [ngStyle.xs]="{'width':'200px'}">
           <div> <img src="{{data.image}}" width="100px" height="100px"/></div>
            <strong>{{data.heading}}</strong>
            <p>{{data.content}}</p>
        </div>
    </div>
</div>
<div *ngIf="showDiv.visitStore" style="color: white;">
    <div style="text-align: center;" fxLayout fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <div *ngFor="let data of howsItWork1" fxFlex.gt-sm="15" [ngStyle.xs]="{'width':'200px'}" >
           <div> <img src="{{data.image}}" width="100px" height="100px" /></div>
            <strong>{{data.heading}}</strong>
            <p>{{data.content}}</p>
        </div>
    </div>
</div>
</div>