import { Component, OnInit } from '@angular/core';
import { Iwork } from '../../models/hows-it-work.model';

@Component({
  selector: 'app-hows-it-work',
  templateUrl: './hows-it-work.component.html',
  styleUrls: ['./hows-it-work.component.scss']
})
export class HowsItWorkComponent implements OnInit {

  showDiv = {
    repairOnline : true,
    visitStore : false
  }
  constructor() { }

  ngOnInit(): void {
  }

  howsItWork1:Iwork[]=[
    {image:"assets/howItWorks/02.png",heading:'Visit Store',content:'Walk-in to your nearest store and handover the device.'},
    {image:'assets/howItWorks/03.png',heading:'Device Repaired',content:'You get a notification once device is Repaired & ready for delivery.'},
    {image:'assets/howItWorks/01.png',heading:'Collect & Pay',content:'Collect device from store & PAY by Cash / Card.'}
  ];
  howsItWork2:Iwork[]=[
    {image:'assets/howItWorks/04.png',heading:'Book Your Repair',content:'View prices upfront and book repair online. Pay by Card or Choose Cash on Delivery.'},
    {image:'assets/howItWorks/02.png',heading:'Free Pickup',content:'Relax while our executives pick the device from your place.'},
    {image:'assets/howItWorks/03.png',heading:'Diagnosis & Repair',content:'Get a 30 point quality check report on your mail while your device is repaired.'},
    {image:'assets/howItWorks/01.png',heading:'Receive & Pay',content:'Receive the device at your place and pay.'}
  ];
}
