<div fxLayoutAlign="center center" class="login-container">
  <mat-card>
    <h3>Sign in to Repairing Bazar</h3>
    <form fxLayout="column">
      <mat-form-field appearance="outline">
        <input type="email" matInput placeholder="Email" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input type="password" matInput placeholder="Password" />
      </mat-form-field>
      <span>Forgot password?</span>
      <button mat-raised-button color="warn">Sign in</button>
      <span class="text">Don't have an account? <span class="sign-up">Sign up</span>
      </span>
    </form>
    <div>
      <h3>Or Login With</h3>
      <div fxLayout>
        <asl-google-signin-button></asl-google-signin-button>
        <button (click)="signInWithFB()" mat-icon-button>
          <mat-icon>facebook</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>