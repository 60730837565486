import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/common/modules/material.module';

import { CheckoutAddressComponent } from '../components/checkout-address/checkout-address.component';
import { CheckoutBagComponent } from '../components/checkout-bag/checkout-bag.component';
import { CheckoutHeaderComponent } from '../components/checkout-header/checkout-header.component';

@NgModule({
  declarations: [
    CheckoutAddressComponent,
    CheckoutBagComponent,
    CheckoutHeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    CheckoutAddressComponent,
    CheckoutBagComponent,
    CheckoutHeaderComponent
  ]
})

export class CheckoutModule { }