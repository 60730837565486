<div [formGroup]="placeOrderForm">
    <form formGroupName="PlaceOrderGroup">

        <h2>Select Suitable Date & Time</h2>
        <ul>
            <li *ngFor="let data of dateTime;let i=index">
                <input type="radio" id="{{data.id}}" formControlName="PlaceOrderCtrl" [value]="data"
                    name="PlaceOrderCtrl" (change)="scheduleDate()" required />
                <label for="{{data.for}}" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon>date_range</mat-icon>
                    <span>{{data.date}}</span>
                </label>
            </li>

        </ul>

    </form>
</div>