import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Ibag, Icart } from '../../models/checkout.model';

@Component({
  selector: 'app-checkout-bag',
  templateUrl: './checkout-bag.component.html',
  styleUrls: ['./checkout-bag.component.scss']
})

export class CheckoutBagComponent {

  constructor(private _snackBar: MatSnackBar) { }

  bag: Ibag[] = [
    {
      image: 'assets/top-offers/pampers-pants.jpg',
      name: 'HUGGIES WONDER PANTS S 86S',
      unit: '1 Unit(s) / pack',
      price: 'MRP / Pack 1,049.00 MRP Total Rs.1,049.00',
      qty: 1
    },
    {
      image: 'assets/images/coronavirus.jpg',
      name: 'HUGGIES WONDER ',
      unit: '1 Unit(s) / pack',
      price: 'MRP / Pack 1,049.00 MRP Total Rs.1,049.00',
      qty: 1
    }
  ]

  selectedIndex: number;

  select(index: number) {
    this.selectedIndex = index;
  }

  showMore = 'show more'
  hidden: boolean;
  arrow = 'keyboard_arrow_down';

  toggle() {
    this.hidden = !this.hidden;
    if (this.hidden) {
      this.showMore = 'show less';
      this.arrow = 'keyboard_arrow_up';
    }

    if (!this.hidden) {
      this.showMore = ' show more';
      this.arrow = 'keyboard_arrow_down';
    }
  }

  cart: Icart[] = [{ name: 'Bag Total', price: 1100 },
  { name: 'Bag Discount', price: -6450 },
  { name: 'Coupon Discount', price: -101 },
  { name: 'Order Total', price: 5054 },
  { name: 'Delivery Charges', price: 99 },
  { name: 'Total', price: 5054 }
  ]

  inc(data) {
    if (data.qty != 5) {
      data.qty += 1;
    }
    console.log(data);
  }

  dec(data) {
    if (data.qty != 1) {
      data.qty = data.qty - 1;
    }
    console.log(data);
  }
}