import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { MaterialModule } from 'src/app/common/modules/material.module';

import { HomePageSliderComponent } from '../../home-page/components/home-page-slider/home-page-slider.component';
import { HowsItWorkComponent } from '../../home-page/components/hows-it-work/hows-it-work.component';
import { ImageSliderModule } from 'src/app/common/modules/image-slider.module';
import { MobileAccessoriesComponent } from '../components/mobile-accessories/mobile-accessories.component';
import { MobileBrandComponent } from '../components/mobile-brand/mobile-brand.component';
import { MobileDetailsComponent } from '../components/mobile-details/mobile-details.component';
import { MobileIssuesComponent } from '../components/mobile-issues/mobile-issues.component';
import { MobileModelsComponent } from '../components/mobile-models/mobile-models.component';
import { MobileRepairComponent } from '../pages/mobile-repair.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PlaceOrderComponent } from '../components/place-order/place-order.component';
import { RepairingBazarAboutComponent } from '../../home-page/components/repairing-bazar-about/repairing-bazar-about.component';
import { RepairingServicesComponent } from '../../home-page/components/repairing-services/repairing-services.component';
import { ToastrModule } from 'ngx-toastr';

export const options: Partial<IConfig> = {
  thousandSeparator: "'"
};

@NgModule({
  declarations: [
    HomePageSliderComponent,
    HowsItWorkComponent,
    MobileAccessoriesComponent,
    MobileBrandComponent,
    MobileDetailsComponent,
    MobileIssuesComponent,
    MobileModelsComponent,
    MobileRepairComponent,
    PlaceOrderComponent,
    RepairingBazarAboutComponent,
    RepairingServicesComponent
  ],
  imports: [
    CarouselModule,
    CommonModule,
    ImageSliderModule,
    MaterialModule,
    Ng2SearchPipeModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }),
    NgxMaskModule.forRoot(options)
  ],
  exports: [
    HomePageSliderComponent,
    HowsItWorkComponent,
    MobileAccessoriesComponent,
    MobileBrandComponent,
    MobileDetailsComponent,
    MobileIssuesComponent,
    MobileModelsComponent,
    MobileRepairComponent,
    PlaceOrderComponent,
    RepairingBazarAboutComponent,
    RepairingServicesComponent
  ]
})

export class MobileRepairModule { }