<div class="container">
    <strong [ngStyle.xs]="{'font-size.px':'5px'}">Repairing By Category</strong>
    <owl-carousel-o [class.active]="isNavinCenter" [options]="customOptions">
        <ng-container *ngFor="let item of repairingCategory">
            <ng-template carouselSlide>
                <div class="item-container">
                    <a href="#">
                        <img src="{{item.imageSource}}" class="image-size">
                        <div [ngStyle.xs]="{'font-size.px':'6px'}">{{item.text}}</div>
                    </a>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>