<div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap.lt-md="15px" fxLayout.lt-md="column"
    [ngStyle.gt-sm]="{'padding': '20px'}">
    <div fxFlex="60%">
        <div fxLayout="column" fxLayoutGap="30px">
            <div class="available-offers-border-outline">
                <h4>Available Offers</h4>
                <ul>
                    <li>10 % Cashback upto Rs 200 on transaction with PayZapp.TCA</li>
                    <li *ngIf="hidden">10 % Cashback upto Rs 200 on transaction with Mobikwik.TCA </li>
                    <li *ngIf="hidden">Flat RS 200 Cashback for First Time user of Airtel payment bank with Myntra</li>
                </ul>
                <span (click)="toggle()" fxLayout class="show-more-less">{{showMore}}
                    <mat-icon [ngStyle]="{'font-size':'18px'}">{{arrow}}</mat-icon>
                </span>
            </div>
            <div class="free-delivery" fxLayout>
                <mat-icon [ngStyle]="{'font-size': '35px','position':'relative','top':'6px'}">two_wheeler</mat-icon>
                <div [ngStyle]="{'margin': '15px'}"><span [ngStyle]="{'color': '#696B79'}">YAY!
                    </span>&nbsp;<strong>Free
                        Delivery</strong> &nbsp;
                    <span [ngStyle]="{'color': '#696B79'}">in this order</span>
                </div>
            </div>
            <div fxLayout fxLayout.xs="column" fxLayoutGap.xs="5px" fxLayoutAlign.gt-xs="space-between center" fxFlex
                class="shopping-bag">
                <span>My Shopping Bag(1 item)</span>
                <span>Total Rs. 1024</span>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" class="item-description-border-outline" *ngFor="let data of bag">
                <div fxLayout fxLayoutGap="10px" fxLayout.lt-sm="column" [ngStyle]="{'padding':'0 15px 0 15px'}">
                    <img src="{{data.image}}" width="100px" height="100px">
                    <div fxLayout="column">
                        <h4 [ngStyle]="{'margin-bottom':'3px'}">{{data.name}}</h4>
                        <h6 [ngStyle]="{'margin':'0'}">{{data.unit}}</h6>
                        <h5 [ngStyle]="{'margin-top':'5px'}">{{data.price}}</h5>
                        <div fxLayoutAlign="start">
                            <span>
                                <button mat-icon-button class="add-button" (click)="dec(data)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                                {{data.qty}}
                                <button mat-icon-button [ngStyle]="{'margin-left': '10px'}" (click)="inc(data);">
                                    <mat-icon>add_circle</mat-icon>
                                </button></span>
                        </div>
                    </div>
                </div>
                <div [ngStyle]="{'padding': '0 15px 0 15px'}">
                    <mat-divider></mat-divider>
                </div>
                <div fxLayout fxLayoutGap="50px" fxLayoutGap="10px" fxLayout.xs="column"
                    [ngStyle]="{'padding': '15px'}"><button mat-stroked-button>REMOVE</button>
                    <mat-divider [vertical]="true" fxHide.xs></mat-divider>
                    <button mat-stroked-button>MOVE TO WHISHLIST</button>
                </div>
            </div>
            <div fxLayout fxLayoutAlign="space-between" class="add-to-cart-outline-border">
                <div fxLayout>
                    <mat-icon class="bookmark-keyboard-mat-icon">bookmark_border</mat-icon>
                    <strong [ngStyle]="{'font-size': 'smaller'}">ADD TO WHISHLIST</strong>
                </div>
                <div>
                    <mat-icon class="bookmark-keyboard-mat-icon">keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <mat-divider [vertical]="true"></mat-divider>

    <div fxLayout="column" fxFlex="30%" fxLayoutGap="14px" [ngStyle.lt-md]="{'border-radius':'5px','padding':'20px'}"
        class="coupon">
        <div>
            <h5>COUPONS</h5>
            <div fxLayout fxLayoutAlign="space-between center">
                <div fxLayout fxLayoutGap="15px">
                    <mat-icon class="local_offer-mat-icon">local_offer</mat-icon>
                    <span>Apply Coupons</span>
                </div>
                <button mat-stroked-button color="white">Apply</button>
            </div>
        </div>
        <div class="pricing-detail">PRICE DETAILS</div>
        <div fxLayout fxLayoutAlign="space-between" *ngFor="let data of cart; let i =index "
            [ngStyle]="{'font-size': '14px'}">
            <span *ngIf="i<5">{{data.name}}</span>
            <span *ngIf="i == 5"><strong>{{data.name}}</strong></span>
            <span *ngIf="i<4">&#8377;{{data.price}}</span>
            <span *ngIf="i==4">&#8377;<del>{{data.price}}</del>&nbsp;Free</span>
            <span *ngIf="i>4"><strong>&#8377;{{data.price}}</strong></span>
        </div>
        <button mat-stroked-button matStepperNext [ngStyle.sm]="{'width':'150px'}">PLACE ORDER</button>
    </div>
</div>