<owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let data of slider">
        <ng-template carouselSlide>
            <div class="item-container" fxLayoutAlign="center">
                <div fxLayout="column" fxFlex>
                    <img src="{{data.imageSource}}" alt=" " class="img-responsive">
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>