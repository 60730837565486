import { Component } from '@angular/core';

import { IImageSlider } from 'src/app/common/models/image-slider.model';

@Component({
  selector: 'app-home-page-slider',
  templateUrl: './home-page-slider.component.html',
  styleUrls: ['./home-page-slider.component.scss']
})

export class HomePageSliderComponent {

  imageSlider: IImageSlider[] = [
    {
      imageSource: "assets/slider/RB_slide.jpg",
    },
    {
      imageSource: "assets/slider/RB_slide1.jpg",
    },   {
      imageSource: "assets/slider/RB_slide2.jpg",
    },
  ];
}